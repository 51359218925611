.overlay {
  background: rgba(255,255,255,.25);
  /*box-shadow: 0 0 0 1px rgba(50, 55, 60, 0.8);*/
  box-sizing: border-box;
  z-index: 2;
}

.overlay_active {
  background: #2ebcc0;
  border: 1px solid #fff;
  box-shadow: 0 0 0 1px rgba(50, 55, 60, 0.8);
  box-sizing: border-box;
  z-index: 2;
}

.overlay_endpoint {
  background: orange;
  border: 1px solid #fff;
  box-shadow: 0 0 0 1px rgba(50, 55, 60, 0.8);
  box-sizing: border-box;
  z-index: 2;
}

.btn {
  background: transparent;
  border: 0;
  outline: none;
  padding: 0;
}
