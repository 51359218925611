.button {
  composes: callToAction from './button.css';
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  margin-bottom: 0.5rem;
  margin-top: 0;
  width: 100%;
}
