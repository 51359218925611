.annotation {
  border: 2px solid #fff;
  box-sizing: unset;
  height: 100px;
  opacity: 0.62;
  position: absolute;
  transition: opacity linear 0.2s;
  width: 100px;
}

.annotation:hover {
  opacity: 0.9;
}

.selected {
  composes: annotation;
  box-shadow: 0 0 50px 0 #000;
  opacity: 1;
  z-index: 2;
}

.selected:hover {
  opacity: 1;
}

.selected > .label {
  background-color: rgba(255, 255, 255, 0.95);
  color: inherit;
  font-size: 12px;
  font-weight: bold;
  left: calc(50% - 90px);
  padding: 4px 6px;
  position: relative;
  text-align: center;
  top: -36px;
  width: 180px;
  z-index: 1;
}

.selected > .label::after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid rgba(255, 255, 255, 0.95);
  bottom: -6px;
  content: '';
  height: 0;
  left: calc(50% - 3px);
  position: absolute;
  width: 0;
}

.handleCorner {
  background-color: #2ebcc0;
  border: 1px solid #fff;
  box-shadow: 0 0 0 1px rgba(50, 50, 50, 0.8);
}
