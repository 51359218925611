.button {
  background: rgba(255, 255, 255, 0.95);
  border: 0;
  bottom: 20px;
  font-size: 24px;
  height: 60px;
  left: 20px;
  padding: 0;
  position: absolute;
  transition: all linear 0.2s;
  width: 60px;
}

.button:hover {
  background:rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.pane {
  background: rgba(255, 255, 255, 0.95);
  bottom: 84px;
  font-size: 12pt;
  left: 20px;
  padding: 20px;
  position: absolute;
  transition: all linear 0.2s;
  width: 350px;
  z-index: 20
}

.pane > h2 {
	border-bottom: 1px solid #ccc;
	padding-bottom: 8px;
}

.pane > p{
	line-height: 1.4;
	margin-top: 12px;
}
