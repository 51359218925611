.row {
  align-items: center;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
  padding: 1.25rem 1rem;
}

.grouprow {
  align-items: flex-end;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
  padding-top: 1.75rem;
  padding-left: 1rem;
  padding-bottom: 1.75rem;
  background: rgba(240,240,240,0.8);
}

.iconColumn {
  align-items: stretch;
  display: flex;
  justify-content: stretch;
}

.iconBg {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex: 1;
  justify-content: center;
  height: 2.875rem;
  margin-right: 1rem;
  overflow: hidden;
  width: 2.875rem;
}

.iconBg svg {
  fill: #fff;
  height: 1.5rem;
  width: 1.5rem;
}

.iconBgProcessing {
  composes: iconBg;
  background-color: #42b4d0;
}

.iconBgReviewComplete,
.iconBgLabeling {
  composes: iconBg;
  background-color: #0eafa6;
}

.iconBgComplete {
  composes: iconBg;
  background-color: #33cc99;
}

.iconBgError,
.iconBgReviewing {
  composes: iconBg;
  background-color: #fd7267;
}

.nameColumn {
  flex: 2;
  overflow: hidden;
}

.nameColumn > p {
  color: #464e67;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 1.14;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nameColumn > small {
  color: #828080;
  font-size: 0.75rem;
}

.progressColumn {
  flex: 4;
}

.progressColumn strong {
  color: #464e67;
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 1.37;
}

.errorMsg {
  color: #828080;
  font-size: 0.75rem;
  margin: 0;
}

.progressRow {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-right: 1rem;
}

.progressRow > small {
  color: #828080;
  flex: 4;
  font-size: 0.75rem;
  margin-right: 1.3125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fill {
  background-color: #eee;
  flex: 6;
  height: 10px;
  position: relative;
}

.fillContent {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.fillContentReviewComplete,
.fillContentLabeling {
  composes: fillContent;
  background-color: #0eafa6;
}

.fillContentComplete {
  composes: fillContent;
  background-color: #33cc99;
}

.fillContentProcessing {
  composes: fillContent;
  background-color: #42b4d0;
}

@keyframes pulse {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.fillContentProcessing:after {
  animation: pulse ease-in-out 3s infinite;
  background-image: linear-gradient(
    135deg,
    transparent,
    rgba(255, 255, 255, 0.5)
  );
  background-size: cover;
  content: '';
  display: block;
  height: 100%;
  z-index: 2;
}

.fillContentQC {
  composes: fillContent;
  background-color: #fd7267;
}

.btnColumn {
  align-items: stretch;
  display: flex;
  justify-content: stretch;
  position: relative;
  width: 7rem;
}

.sharedBtn {
  align-items: center;
  background: unset;
  border: none;
  color: rgba(130, 128, 128, 0.3);
  cursor: pointer;
  display: flex;
  flex: 1;
  font-family: inherit;
  font-size: 0.78125rem;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.processingBtn {
  composes: sharedBtn;
  border: 1px solid #828080;
  color: #828080;
  cursor: default;
  opacity: 0.2;
}

.reviewBtn {
  composes: sharedBtn;
  background-color: #fd7267;
  color: #fff;
  font-weight: 500;
}

.reviewBtn:hover {
  background-color: #fd7267;
  border: none;
  color: #fff;
  filter: brightness(90%);
}

.reviewBtn ~ .menuBtn {
  border: none;
  background-color: #fd7267;
  color: #fff;
}

.reviewBtn ~ .menuBtn > svg {
  color: currentColor;
}

.menuBtn {
  composes: sharedBtn;
  border: 1px solid #464e67;
  border-left-width: 0;
  flex: 0;
  padding: 0 8px;
}

.menuBtn > svg {
  color: #464e67;
  height: 1rem;
  width: 1rem;
}

.menu {
  background-color: #fff;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  width: 100%;
  z-index: 3;
}

.exportBtn,
.publishBtn,
.viewBtn {
  composes: sharedBtn;
  border: 1px solid #828080;
  color: #828080;
  justify-content: space-between;
  padding: 4px 8px;
  margin-bottom: 5px;
}

.publishBtn_disabled {
  composes: sharedBtn;
  border: 1px solid #a4a0a0;
  color: #a4a0a0;
  justify-content: space-between;
  padding: 4px 8px;
  margin-bottom: 5px;
  cursor:not-allowed
}

.exportBtn > svg,
.publishBtn > svg,
.viewBtn > svg {
  height: 1rem;
  width: 1rem;
}

.viewBtn:hover {
  color: #828080;
}
