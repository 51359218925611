.btn {
  align-items: center;
  background: rgba(79,77,110,1);
  border: 1px solid rgba(240,240,240,1);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  margin: 0;
  margin-left: 20px;
  padding: 0;
}

.btn:hover {
  opacity: 0.75;
}
