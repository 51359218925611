.badge {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  left: 10px;
  padding: 5px;
  position: absolute;
  top: 10px;
}

.errorMessage {
  color: #fff;
  font-size: 150%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
