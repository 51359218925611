.wrapper {
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  width: 25%;
}

.input {
  background: none;
  border: none;
  color: #cecece;
  font-size: 0.75rem;
  font-weight: 500;
  height: auto;
  line-height: 2;
  padding: 0;
}

.clearBtn {
  background: none;
  border: none;
  color: #cecece;
  cursor: pointer;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
