.sidebar {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  max-width: 360px;
  overflow-y: auto;
  padding: 0 20px 20px;
}

.sidebar > * {
  flex: 1;
  margin: 10px 0;
  max-height: calc(50% - 10px);
  min-height: calc((100% - 40px) / 3);
  z-index: 2;
}

.sidebar > :first-child {
  margin-top: 0;
}

.sidebar > :last-child {
  margin-bottom: 0;
}

.sidebar > :only-child {
  margin: 0;
  max-height: 100%;
  min-height: auto;
}
