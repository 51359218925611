.row {
  border-bottom: 1px solid #eee;
  border-left: 6px solid transparent;
  padding: 7px;
  transition: all linear 0.2s;
}

.row:hover {
  background: #fff;
  cursor: pointer;
}

.rowActive {
  composes: row;
  background: #fff;
  border-left-color: #2ebcc0;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-right:20px;
}

.header h5 {
  flex: 1;
  font-size: 12px;
  margin: 0;
}

.trash,
.visible {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.trash {
  margin-right: 10px;
}

.inputRow {
  display: flex;
}

.inputRow > * {
  flex: 1;
  max-width:36%;
  margin-left:2px;
  margin-right:2px;
}

.inputRow > input {
  height:2.5em;
  max-width:15%;
}
.inputRow > select {
  height:2.5em;
}