.container {
  color: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100% - 90px);
  justify-content: flex-start;
  left: calc(calc(calc(100% - 360px) / 2) - 160px);
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 320px;
  z-index: 2;
}

.linkRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  pointer-events: all;
}

.listBody {
  background-color: rgba(255, 255, 255, 0.95);
  color: #212529;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 6px 0 12px;
  pointer-events: all;
  text-align: left;
}

.listBody::before {
  border-bottom: 6px solid rgba(255, 255, 255, 0.95);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: '';
  display: block;
  height: 0;
  margin: 0 auto;
  position: relative;
  top: -6px;
  width: 0;
}

.listWrapper {
  height: 100%;
}

.filters {
  border-bottom: 1px solid #ccc;
  padding: 20px 20px 10px;
}

.filters > p {
  margin-bottom: 0;
}

.filters > p:last-child {
  margin-top: 5px;
}

.filtersRow {
  display: flex;
}

.filtersRow > fieldset:first-child {
  margin-right: 10px;
}

.filtersRow > fieldset:last-child {
  margin-left: 10px;
}

.filtersRow > fieldset:only-child {
  margin: 0;
}

.filtersRow > fieldset > label {
  color: #2ebcc0;
  font-size: 12px;
  font-weight: bolder;
}

.item {
  border-bottom: 1px solid #eee;
  border-left: 6px solid transparent;
  color: #000;
  font-size: 12px;
  overflow: hidden;
  padding: 12px;
  text-overflow: ellipsis;
  transition: all linear 0.2s;
  white-space: nowrap;
}

.item:hover {
  background-color: #fff;
  color: #000;
}

.active {
  composes: item;
  background-color: #fff;
  border-left-color: #2ebcc0;
  font-weight: bolder;
}

.prev,
.next {
  color: #fff;
  display: inline-block;
  font-size: 18px;
  text-align: center;
  text-shadow: 0 1px 2px #000;
  vertical-align: middle;
  width: 10%;
}

.prev[disabled],
.next[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.prev:hover,
.next:hover {
  color: #fff;
}

.counter {
  background: transparent;
  border: 0;
  color: inherit;
  display: inline-block;
  flex: 1;
  font-family: inherit;
  font-size: initial;
  text-align: center;
  text-shadow: 0 1px 2px #000;
  transition: all linear 0.2s;
  vertical-align: middle;
}

.counter:hover {
  cursor: pointer;
  opacity: 0.8;
}

.counter > svg {
  margin-left: 10px;
}
