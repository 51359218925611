@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  color: #212529;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

h1, h2, h3, h4, h5 {
  color: inherit;
  font-family: inherit;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

p {
  margin-bottom: 0.5rem;
  margin-top: 0;
}

a {
  background-color: transparent;
  color: #2ebcc0;
  text-decoration: none;
  transition: all linear 0.1s;
}

a:hover {
	color: #33ccd0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

fieldset > label {
  display: block;
}

label {
  font-size: 14px;
}

input,
select,
textarea {
  background-color: white;
  border: 1px solid #bac8c8;
  color: #424c5d;
  font-size: 0.8em;
  height: 44px;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

input::placeholder,
textarea::placeholder {
  color: #cccccc;
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
}

select {
  cursor: pointer;
  transition: all linear 0.2s;
}

select:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

button:active,
button:focus,
input:active,
input:focus,
select:active,
select:focus,
textarea:active,
textarea:focus {
  outline: none;
}

section[role=main] {
  background-color: #404672;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#444444', endColorstr='#333333', GradientType=0);
  background: linear-gradient(#404672, #49395e), url(https://www.allvision.io/wp-content/uploads/2020/01/noise_tile_trans.png);
  background-blend-mode: overlay;
  background-size: 72px;
  height: inherit;
  padding-top: 78px;
  position: relative;
}

/* simplebar overrides */

.simplebar-scrollbar {
  width: 10px;
}

.simplebar-scrollbar:before {
  background: #c1cdce;
  border-radius: 0;
}
