.loginGoogle {
  composes: button from './button.css';
  background-color: #f53d3d;
  border: 0;
  color: #fff;
}

.loginGoogle[disabled] {
  cursor: default;
  opacity: 0.5;
}

.loginGoogle:hover:not([disabled]) {
  background-color: #f65050;
  border: 0;
  color: #fff;
}
