.navbar {
  align-items: center;
  display: flex;
  height: 78px;
  justify-content: space-between;
  padding: 1rem;
  position: fixed;
  user-select: none;
  width: 100%;
  z-index: 3;
}

.browse {
  max-height: 40px;
}
