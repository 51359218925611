.container {
  display: flex;
  flex: 4;
  flex-direction: column;
  margin-top: -70px;
  padding: 10px;
  z-index: 3;
}

.searchRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sortWrapper {
  display: flex;
}

.listContainer {
  background-color: #fefefe;
  box-shadow: 0.2px 2px 12px 0 rgba(26, 33, 63, 0.21);
  flex: 1;
}

.list {
  overflow-y: auto;
}

.emptyState {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.emptyState > p {
  font-size: 125%;
  padding: 20px;
}
