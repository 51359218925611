.label {
  display: block;
  margin-bottom: 8px;
}

.input {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.slider {
  border: 1px solid #ccc;
  height: 36px;
  position: relative;
  width: 90px;
}

.slider::after {
  background-color: #fff;
  content: '';
  cursor: pointer;
  display: block;
  height: 100%;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: left linear 0.4s;
  width: 50%;
}

.sliderChecked {
  composes: slider;
  border-color: #16b2b8;
}

.sliderChecked::after {
  left: 0;
}

.no,
.yes {
  color: #fff;
  display: inline-block;
  height: 100%;
  line-height: 36px;
  text-align: center;
  width: 50%;
}

.no {
  background-color: #ccc;
}

.yes {
  background-color: #16b2b8;
}
