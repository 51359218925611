.wrapper {
  align-items: center;
  color: #fff;
  display: flex;
  flex-basis: 200px;
  font-size: 0.75rem;
}

.select {
  appearance: none;
  background: none;
  border: none;
  color: #31e1e7;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  height: auto;
  line-height: 2;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: underline;
  width: auto;
}

.select:active,
.select:focus {
  border: none;
  outline: none;
}

.select > option {
  color: #464e67;
  font-size: 0.875rem;
}
