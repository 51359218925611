.button {
  background-color: transparent;
  border: 1px solid #bac8c8;
  color: #424c5d;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  line-height: 1;
  margin: 6px 0;
  padding: 20px;
  text-align: center;
  transition: all linear 0.1s;
  width: 100%;
}

.button:hover:not([disabled]) {
  background-color: #fff;
  border: 1px solid #d2dcdc;
  color: #808897;
}

.button[disabled] {
  cursor: default;
  opacity: 0.5;
}

.callToAction {
  composes: button;
  background-color: #2ebcc0;
  border: 0;
  color: #fff;
}

.callToAction:hover:not([disabled]) {
  background-color: #3acdd1;
  border: 0;
  color: #fff;
}
