.addButton {
  background: #2ebcc0;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 28px;
  line-height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 30px;
  text-align: center;
  transition: background linear 0.2s;
  top: 30px;
  width: 28px;
}

.addButton:hover {
  background: #3acdd1;
}

.overlay {
  align-items: center;
  background-color: #212529;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}

.modalContent {
  background: #fff;
  border: none;
  position: relative;
  outline: none;
  width: 30em;
}

.modalHeader,
.modalFooter {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.modalHeader > *,
.modalFooter > * {
  margin: 0;
}

.modalHeader {
  border-bottom: 1px solid #bac8c8;
  padding: 20px;
}

.modalHeader > button {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  padding: 0;
}

.modalFooter {
  padding: 20px;
}

.form {
  padding: 20px;
}

.form > fieldset {
  margin-bottom: 15px;
}

.form > fieldset > label {
  margin-bottom: 5px;
}

.form > fieldset > label > small {
  color: #bac8c8;
  font-size: smaller;
}

.cancelButton {
  composes: button from './button.css';
  margin-right: 10px;
}

.submitButton {
  composes: callToAction from './button.css';
  margin-left: 10px;
}

.listItemButton {
  background: none;
  border: none;
  font-family: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  width: 100%;
}

.listItemButton:hover {
  cursor: pointer;
}
