.login {
  background-color: rgba(255, 255, 255, 0.95);
  left: 50%;
  padding: 40px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.heading {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 1.5rem;
}

.field {
  margin-bottom: 1.5rem;
}

.field > label {
  display: block;
  margin-bottom: 0.5rem;
}

.submit {
  composes: callToAction from '../components/button.css';
}

.error {
  color: #f53d3d;
}
