.cam_overlay {
  background-image: url('ping.svg');
  border-radius: 200px;
  box-sizing: border-box;
  height: 40px !important;
  width: 40px !important;
  z-index: 2;
}

.container {
  background-color:rgba(255, 255, 255, .95);
  left: 20px;
  position: absolute;
  top: 20px;
  width: 400px;
  z-index: 3;
  border: 4px solid rgba(255, 255, 255, .95);
}

.pano_overlay {
  box-shadow: 0px 0px 12px rgba(38, 48, 76, 0.2);
  height: 300px;
}

.coord_inlay {
  margin-top:5px;
  width:100%;
  cursor:pointer;
  display:table-cell;
  color:black;
}
