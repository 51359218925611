.row {
  border-bottom: 1px solid #eee;
  border-left: 6px solid transparent;
  padding: 12px;
  transition: all linear 0.2s;
}

.row:hover {
  background: #fff;
  cursor: pointer;
}

.rowActive {
  composes: row;
  background: #fff;
  border-left-color: #2ebcc0;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.header h5 {
  font-size: 12px;
  margin: 0;
}

.trash {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  padding: 0;
  text-align: center;
}
