.nav {
  background: rgba(255, 255, 255, 0.95);
  bottom: 20px;
  display: flex;
  height: 60px;
  left: calc(calc(calc(100% - 360px) / 2) - 100px);
  position: absolute;
  width: 200px;
  z-index: 2;
}

.nav > span {
  align-self: center;
  flex: 1;
  text-align: center;
}

.button {
  background: transparent;
  border: 0;
  flex: 1;
  font-size: 24px;
  max-width: 60px;
  padding: 0;
  transition: all linear 0.2s;
}

.button:hover:not([disabled]) {
  background: #fff;
  cursor: pointer;
}

.button[disabled],
.button[disabled] ~ span {
  opacity: 0.5;
}
