.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 0.5rem 0.5rem 1rem;
}

.helpBtn {
  align-items: center;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  font-size: 0.875rem;
  height: 40px;
  justify-content: center;
  margin-bottom: 0.5rem;
  transition: opacity 0.2s linear;
  width: 100%;
}

.helpBtn:hover {
  color: #fff;
  opacity: 0.75;
}

.linksRow {
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 1rem;
}

.metadata {
  background-color: rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
  padding: 1rem;
}

.metadata small {
  color: rgba(255, 255, 255, 0.5);
  display: block;
}

.copyright {
  color: rgba(255, 255, 255, 0.2);
  line-height: 1;
  margin-bottom: 0;
}

.copyright small {
  font-size: 0.75rem;
  line-height: inherit;
}
