.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  width: 100%;
}

.container img {
  display: block;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  max-height: 126px;
  max-width: 126px;
  overflow: hidden;
}

.container p {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}
