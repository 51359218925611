.box {
  background-color: rgba(255, 255, 255, .95);
	box-shadow: 0 0 12px rgba(38, 48, 76, 0.2);
	color: #424c5d;
	margin-bottom: 40px;
	position: relative;
  width: 25%;
  margin: 10px;
}

.small {
  composes: box;
  width: 320px;
  margin-bottom: 20px;
}

.box > header {
  border-bottom: 1px solid #bac8c8;
  padding: 30px 30px 20px;
  height: 64px;
}

.small > header {
  border-bottom: 1px solid #bac8c8;
  padding: 20px 20px 15px;
}

.box > header > h4 {
  margin-bottom: 0;
}

.box ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h4 {
  float:left;
  margin-right:10px;
}

.count{
  background-color: #454965;
  color: white;
  border-radius: 100px;
  display: inline-block;
  padding: 6px;
  text-align: center;
  min-width: 27px;
  font-size: .6em;
}
