.spinnerContainer {
	border-radius: 3px;
	height: 96px;
	left: 50%;
	opacity: 0.90;
	overflow: hidden;
  position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all 1s linear;
	width: 96px;
}

.spinnerContainer > img {
  height: 100%;
  width: 100%;
}
