.wrapper {
  max-height: 100%;
  max-width: 100%;
}

.image {
  box-shadow: 0 0 8px rgba(36, 30, 38, 0.6);
  max-height: 100%;
  max-width: 100%;
}

.image:hover {
  cursor: move;
}
