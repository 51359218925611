@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);
.Icon_icon__2o_0R {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  stroke: currentColor;
  stroke-width: 0;
  vertical-align: middle;
  width: 1em;
}

.button_button__CHc9H {
  background-color: transparent;
  border: 1px solid #bac8c8;
  color: #424c5d;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  line-height: 1;
  margin: 6px 0;
  padding: 20px;
  text-align: center;
  transition: all linear 0.1s;
  width: 100%;
}

.button_button__CHc9H:hover:not([disabled]) {
  background-color: #fff;
  border: 1px solid #d2dcdc;
  color: #808897;
}

.button_button__CHc9H[disabled] {
  cursor: default;
  opacity: 0.5;
}

.button_callToAction__3CNMs {
  background-color: #2ebcc0;
  border: 0;
  color: #fff;
}

.button_callToAction__3CNMs:hover:not([disabled]) {
  background-color: #3acdd1;
  border: 0;
  color: #fff;
}

.ProcessingJobs_addButton__1FW9j {
  background: #2ebcc0;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 28px;
  line-height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 30px;
  text-align: center;
  transition: background linear 0.2s;
  top: 30px;
  width: 28px;
}

.ProcessingJobs_addButton__1FW9j:hover {
  background: #3acdd1;
}

.ProcessingJobs_overlay__7gtRf {
  align-items: center;
  background-color: #212529;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}

.ProcessingJobs_modalContent__3a3aa {
  background: #fff;
  border: none;
  position: relative;
  outline: none;
  width: 30em;
}

.ProcessingJobs_modalHeader__1ke54,
.ProcessingJobs_modalFooter__1DfeT {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.ProcessingJobs_modalHeader__1ke54 > *,
.ProcessingJobs_modalFooter__1DfeT > * {
  margin: 0;
}

.ProcessingJobs_modalHeader__1ke54 {
  border-bottom: 1px solid #bac8c8;
  padding: 20px;
}

.ProcessingJobs_modalHeader__1ke54 > button {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  padding: 0;
}

.ProcessingJobs_modalFooter__1DfeT {
  padding: 20px;
}

.ProcessingJobs_form__28otm {
  padding: 20px;
}

.ProcessingJobs_form__28otm > fieldset {
  margin-bottom: 15px;
}

.ProcessingJobs_form__28otm > fieldset > label {
  margin-bottom: 5px;
}

.ProcessingJobs_form__28otm > fieldset > label > small {
  color: #bac8c8;
  font-size: smaller;
}

.ProcessingJobs_cancelButton__2pjWX {
  margin-right: 10px;
}

.ProcessingJobs_submitButton__3sK7- {
  margin-left: 10px;
}

.ProcessingJobs_listItemButton__21wZr {
  background: none;
  border: none;
  font-family: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  width: 100%;
}

.ProcessingJobs_listItemButton__21wZr:hover {
  cursor: pointer;
}

.CreateJob_btn__3PInF {
  align-items: center;
  background: rgba(79,77,110,1);
  border: 1px solid rgba(240,240,240,1);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  margin: 0;
  margin-left: 20px;
  padding: 0;
}

.CreateJob_btn__3PInF:hover {
  opacity: 0.75;
}

.DashboardListItem_row__3aAAr {
  align-items: center;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
  padding: 1.25rem 1rem;
}

.DashboardListItem_grouprow__CBGUS {
  align-items: flex-end;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
  padding-top: 1.75rem;
  padding-left: 1rem;
  padding-bottom: 1.75rem;
  background: rgba(240,240,240,0.8);
}

.DashboardListItem_iconColumn__3TaF7 {
  align-items: stretch;
  display: flex;
  justify-content: stretch;
}

.DashboardListItem_iconBg__c21vC {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex: 1 1;
  justify-content: center;
  height: 2.875rem;
  margin-right: 1rem;
  overflow: hidden;
  width: 2.875rem;
}

.DashboardListItem_iconBg__c21vC svg {
  fill: #fff;
  height: 1.5rem;
  width: 1.5rem;
}

.DashboardListItem_iconBgProcessing__3_jKS {
  background-color: #42b4d0;
}

.DashboardListItem_iconBgReviewComplete__6uKps,
.DashboardListItem_iconBgLabeling__2uG_p {
  background-color: #0eafa6;
}

.DashboardListItem_iconBgComplete__W5RT3 {
  background-color: #33cc99;
}

.DashboardListItem_iconBgError__MnDxG,
.DashboardListItem_iconBgReviewing__16tLc {
  background-color: #fd7267;
}

.DashboardListItem_nameColumn__3IpeJ {
  flex: 2 1;
  overflow: hidden;
}

.DashboardListItem_nameColumn__3IpeJ > p {
  color: #464e67;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 1.14;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DashboardListItem_nameColumn__3IpeJ > small {
  color: #828080;
  font-size: 0.75rem;
}

.DashboardListItem_progressColumn__GHNvd {
  flex: 4 1;
}

.DashboardListItem_progressColumn__GHNvd strong {
  color: #464e67;
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 1.37;
}

.DashboardListItem_errorMsg__OJtio {
  color: #828080;
  font-size: 0.75rem;
  margin: 0;
}

.DashboardListItem_progressRow__3AwRD {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  margin-right: 1rem;
}

.DashboardListItem_progressRow__3AwRD > small {
  color: #828080;
  flex: 4 1;
  font-size: 0.75rem;
  margin-right: 1.3125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DashboardListItem_fill__1xrsZ {
  background-color: #eee;
  flex: 6 1;
  height: 10px;
  position: relative;
}

.DashboardListItem_fillContent__3vlmD {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.DashboardListItem_fillContentReviewComplete__3yiu8,
.DashboardListItem_fillContentLabeling__3IEMv {
  background-color: #0eafa6;
}

.DashboardListItem_fillContentComplete__KUVAu {
  background-color: #33cc99;
}

.DashboardListItem_fillContentProcessing__3oHpo {
  background-color: #42b4d0;
}

@-webkit-keyframes DashboardListItem_pulse__2K9RJ {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes DashboardListItem_pulse__2K9RJ {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.DashboardListItem_fillContentProcessing__3oHpo:after {
  -webkit-animation: DashboardListItem_pulse__2K9RJ ease-in-out 3s infinite;
          animation: DashboardListItem_pulse__2K9RJ ease-in-out 3s infinite;
  background-image: linear-gradient(
    135deg,
    transparent,
    rgba(255, 255, 255, 0.5)
  );
  background-size: cover;
  content: '';
  display: block;
  height: 100%;
  z-index: 2;
}

.DashboardListItem_fillContentQC__1oO9q {
  background-color: #fd7267;
}

.DashboardListItem_btnColumn__3-HCa {
  align-items: stretch;
  display: flex;
  justify-content: stretch;
  position: relative;
  width: 7rem;
}

.DashboardListItem_sharedBtn__30j-E {
  align-items: center;
  background: unset;
  border: none;
  color: rgba(130, 128, 128, 0.3);
  cursor: pointer;
  display: flex;
  flex: 1 1;
  font-family: inherit;
  font-size: 0.78125rem;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.DashboardListItem_processingBtn__2WSh7 {
  border: 1px solid #828080;
  color: #828080;
  cursor: default;
  opacity: 0.2;
}

.DashboardListItem_reviewBtn__3NI7d {
  background-color: #fd7267;
  color: #fff;
  font-weight: 500;
}

.DashboardListItem_reviewBtn__3NI7d:hover {
  background-color: #fd7267;
  border: none;
  color: #fff;
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.DashboardListItem_reviewBtn__3NI7d ~ .DashboardListItem_menuBtn__2xYAe {
  border: none;
  background-color: #fd7267;
  color: #fff;
}

.DashboardListItem_reviewBtn__3NI7d ~ .DashboardListItem_menuBtn__2xYAe > svg {
  color: currentColor;
}

.DashboardListItem_menuBtn__2xYAe {
  border: 1px solid #464e67;
  border-left-width: 0;
  flex: 0 1;
  padding: 0 8px;
}

.DashboardListItem_menuBtn__2xYAe > svg {
  color: #464e67;
  height: 1rem;
  width: 1rem;
}

.DashboardListItem_menu__3w6rs {
  background-color: #fff;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  width: 100%;
  z-index: 3;
}

.DashboardListItem_exportBtn__hCJ9O,
.DashboardListItem_publishBtn__83H78,
.DashboardListItem_viewBtn__3_mDI {
  border: 1px solid #828080;
  color: #828080;
  justify-content: space-between;
  padding: 4px 8px;
  margin-bottom: 5px;
}

.DashboardListItem_publishBtn_disabled__Fnwug {
  border: 1px solid #a4a0a0;
  color: #a4a0a0;
  justify-content: space-between;
  padding: 4px 8px;
  margin-bottom: 5px;
  cursor:not-allowed
}

.DashboardListItem_exportBtn__hCJ9O > svg,
.DashboardListItem_publishBtn__83H78 > svg,
.DashboardListItem_viewBtn__3_mDI > svg {
  height: 1rem;
  width: 1rem;
}

.DashboardListItem_viewBtn__3_mDI:hover {
  color: #828080;
}

.DashboardSearch_wrapper__Mezef {
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  width: 25%;
}

.DashboardSearch_input__ITtZz {
  background: none;
  border: none;
  color: #cecece;
  font-size: 0.75rem;
  font-weight: 500;
  height: auto;
  line-height: 2;
  padding: 0;
}

.DashboardSearch_clearBtn__3UMXh {
  background: none;
  border: none;
  color: #cecece;
  cursor: pointer;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.DashboardSort_wrapper__2VA9W {
  align-items: center;
  color: #fff;
  display: flex;
  flex-basis: 200px;
  font-size: 0.75rem;
}

.DashboardSort_select__3jLDZ {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  color: #31e1e7;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  height: auto;
  line-height: 2;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: underline;
  width: auto;
}

.DashboardSort_select__3jLDZ:active,
.DashboardSort_select__3jLDZ:focus {
  border: none;
  outline: none;
}

.DashboardSort_select__3jLDZ > option {
  color: #464e67;
  font-size: 0.875rem;
}

.DashboardList_container__MI4oU {
  display: flex;
  flex: 4 1;
  flex-direction: column;
  margin-top: -70px;
  padding: 10px;
  z-index: 3;
}

.DashboardList_searchRow__1QSRr {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.DashboardList_sortWrapper__3BOK- {
  display: flex;
}

.DashboardList_listContainer__11jLU {
  background-color: #fefefe;
  box-shadow: 0.2px 2px 12px 0 rgba(26, 33, 63, 0.21);
  flex: 1 1;
}

.DashboardList_list___oGZb {
  overflow-y: auto;
}

.DashboardList_emptyState__1uVvQ {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.DashboardList_emptyState__1uVvQ > p {
  font-size: 125%;
  padding: 20px;
}

.LogoutButton_button__1Dan6 {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  margin-bottom: 0.5rem;
  margin-top: 0;
  width: 100%;
}

.UserInfo_container__X9_gn {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  width: 100%;
}

.UserInfo_container__X9_gn img {
  display: block;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  max-height: 126px;
  max-width: 126px;
  overflow: hidden;
}

.UserInfo_container__X9_gn p {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}

.SessionInfo_container__x0syd {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 0.5rem 0.5rem 1rem;
}

.SessionInfo_helpBtn__pa6Tb {
  align-items: center;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  font-size: 0.875rem;
  height: 40px;
  justify-content: center;
  margin-bottom: 0.5rem;
  transition: opacity 0.2s linear;
  width: 100%;
}

.SessionInfo_helpBtn__pa6Tb:hover {
  color: #fff;
  opacity: 0.75;
}

.SessionInfo_linksRow__mJUXW {
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 1rem;
}

.SessionInfo_metadata__h3pI_ {
  background-color: rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
  padding: 1rem;
}

.SessionInfo_metadata__h3pI_ small {
  color: rgba(255, 255, 255, 0.5);
  display: block;
}

.SessionInfo_copyright__1RmJO {
  color: rgba(255, 255, 255, 0.2);
  line-height: 1;
  margin-bottom: 0;
}

.SessionInfo_copyright__1RmJO small {
  font-size: 0.75rem;
  line-height: inherit;
}

.Dashboard_container__2HBuc {
  display: flex;
  height: 100%;
  width: 100%;
}

.Loading_spinnerContainer__3g6_d {
	border-radius: 3px;
	height: 96px;
	left: 50%;
	opacity: 0.90;
	overflow: hidden;
  position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all 1s linear;
	width: 96px;
}

.Loading_spinnerContainer__3g6_d > img {
  height: 100%;
  width: 100%;
}

.GoogleButton_loginGoogle__3fq_8 {
  background-color: #f53d3d;
  border: 0;
  color: #fff;
}

.GoogleButton_loginGoogle__3fq_8[disabled] {
  cursor: default;
  opacity: 0.5;
}

.GoogleButton_loginGoogle__3fq_8:hover:not([disabled]) {
  background-color: #f65050;
  border: 0;
  color: #fff;
}

.Login_login__gFgMw {
  background-color: rgba(255, 255, 255, 0.95);
  left: 50%;
  padding: 40px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.Login_heading__2kw9x {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 1.5rem;
}

.Login_field__1VNLx {
  margin-bottom: 1.5rem;
}

.Login_field__1VNLx > label {
  display: block;
  margin-bottom: 0.5rem;
}

.Login_submit__37Lfw {
}

.Login_error__1K3mf {
  color: #f53d3d;
}

.Navbar_navbar__31-FB {
  align-items: center;
  display: flex;
  height: 78px;
  justify-content: space-between;
  padding: 1rem;
  position: fixed;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  z-index: 3;
}

.Navbar_browse__ATsB_ {
  max-height: 40px;
}

.NotFound_message__2fcyQ {
  color: #fff;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: auto;
}

.CameraOverlay_cam_overlay__nGdWk {
  background-image: url(/static/media/ping.51563b5b.svg);
  border-radius: 200px;
  box-sizing: border-box;
  height: 40px !important;
  width: 40px !important;
  z-index: 2;
}

.CameraOverlay_container__2Qizy {
  background-color:rgba(255, 255, 255, .95);
  left: 20px;
  position: absolute;
  top: 20px;
  width: 400px;
  z-index: 3;
  border: 4px solid rgba(255, 255, 255, .95);
}

.CameraOverlay_pano_overlay__u2G_P {
  box-shadow: 0px 0px 12px rgba(38, 48, 76, 0.2);
  height: 300px;
}

.CameraOverlay_coord_inlay__sRob9 {
  margin-top:5px;
  width:100%;
  cursor:pointer;
  display:table-cell;
  color:black;
}

.AnnotationOverlay_annotation__1rD-V {
  border: 2px solid #fff;
  box-sizing: unset;
  height: 100px;
  opacity: 0.62;
  position: absolute;
  transition: opacity linear 0.2s;
  width: 100px;
}

.AnnotationOverlay_annotation__1rD-V:hover {
  opacity: 0.9;
}

.AnnotationOverlay_selected__3pm6w {
  box-shadow: 0 0 50px 0 #000;
  opacity: 1;
  z-index: 2;
}

.AnnotationOverlay_selected__3pm6w:hover {
  opacity: 1;
}

.AnnotationOverlay_selected__3pm6w > .AnnotationOverlay_label__tcIQ3 {
  background-color: rgba(255, 255, 255, 0.95);
  color: inherit;
  font-size: 12px;
  font-weight: bold;
  left: calc(50% - 90px);
  padding: 4px 6px;
  position: relative;
  text-align: center;
  top: -36px;
  width: 180px;
  z-index: 1;
}

.AnnotationOverlay_selected__3pm6w > .AnnotationOverlay_label__tcIQ3::after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid rgba(255, 255, 255, 0.95);
  bottom: -6px;
  content: '';
  height: 0;
  left: calc(50% - 3px);
  position: absolute;
  width: 0;
}

.AnnotationOverlay_handleCorner__1T7XM {
  background-color: #2ebcc0;
  border: 1px solid #fff;
  box-shadow: 0 0 0 1px rgba(50, 50, 50, 0.8);
}

.PolylineVertex_overlay__9NoeJ {
  background: rgba(255,255,255,.25);
  /*box-shadow: 0 0 0 1px rgba(50, 55, 60, 0.8);*/
  box-sizing: border-box;
  z-index: 2;
}

.PolylineVertex_overlay_active__1Kamb {
  background: #2ebcc0;
  border: 1px solid #fff;
  box-shadow: 0 0 0 1px rgba(50, 55, 60, 0.8);
  box-sizing: border-box;
  z-index: 2;
}

.PolylineVertex_overlay_endpoint__kx5UP {
  background: orange;
  border: 1px solid #fff;
  box-shadow: 0 0 0 1px rgba(50, 55, 60, 0.8);
  box-sizing: border-box;
  z-index: 2;
}

.PolylineVertex_btn__oU3IR {
  background: transparent;
  border: 0;
  outline: none;
  padding: 0;
}

.ImageOverlays_badge__2-S0J {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  left: 10px;
  padding: 5px;
  position: absolute;
  top: 10px;
}

.ImageOverlays_errorMessage__2F8yP {
  color: #fff;
  font-size: 150%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.AnnotatedImage_wrapper__6N7Ld {
  max-height: 100%;
  max-width: 100%;
}

.AnnotatedImage_image__3VZ_0 {
  box-shadow: 0 0 8px rgba(36, 30, 38, 0.6);
  max-height: 100%;
  max-width: 100%;
}

.AnnotatedImage_image__3VZ_0:hover {
  cursor: move;
}

.HelpPane_button__JA-6Q {
  background: rgba(255, 255, 255, 0.95);
  border: 0;
  bottom: 20px;
  font-size: 24px;
  height: 60px;
  left: 20px;
  padding: 0;
  position: absolute;
  transition: all linear 0.2s;
  width: 60px;
}

.HelpPane_button__JA-6Q:hover {
  background:rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.HelpPane_pane__3msM6 {
  background: rgba(255, 255, 255, 0.95);
  bottom: 84px;
  font-size: 12pt;
  left: 20px;
  padding: 20px;
  position: absolute;
  transition: all linear 0.2s;
  width: 350px;
  z-index: 20
}

.HelpPane_pane__3msM6 > h2 {
	border-bottom: 1px solid #ccc;
	padding-bottom: 8px;
}

.HelpPane_pane__3msM6 > p{
	line-height: 1.4;
	margin-top: 12px;
}

.LabelingContainer_container__3Pt1j {
  flex: 1 1;
  max-width: calc(100% - 360px);
  position: relative;
}

.ImageList_container__3wGpx {
  color: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100% - 90px);
  justify-content: flex-start;
  left: calc(calc(calc(100% - 360px) / 2) - 160px);
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 320px;
  z-index: 2;
}

.ImageList_linkRow__1X4II {
  align-items: center;
  display: flex;
  justify-content: space-between;
  pointer-events: all;
}

.ImageList_listBody__3cu5N {
  background-color: rgba(255, 255, 255, 0.95);
  color: #212529;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin: 6px 0 12px;
  pointer-events: all;
  text-align: left;
}

.ImageList_listBody__3cu5N::before {
  border-bottom: 6px solid rgba(255, 255, 255, 0.95);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: '';
  display: block;
  height: 0;
  margin: 0 auto;
  position: relative;
  top: -6px;
  width: 0;
}

.ImageList_listWrapper__4Ss5J {
  height: 100%;
}

.ImageList_filters__1nYkv {
  border-bottom: 1px solid #ccc;
  padding: 20px 20px 10px;
}

.ImageList_filters__1nYkv > p {
  margin-bottom: 0;
}

.ImageList_filters__1nYkv > p:last-child {
  margin-top: 5px;
}

.ImageList_filtersRow__3OkD0 {
  display: flex;
}

.ImageList_filtersRow__3OkD0 > fieldset:first-child {
  margin-right: 10px;
}

.ImageList_filtersRow__3OkD0 > fieldset:last-child {
  margin-left: 10px;
}

.ImageList_filtersRow__3OkD0 > fieldset:only-child {
  margin: 0;
}

.ImageList_filtersRow__3OkD0 > fieldset > label {
  color: #2ebcc0;
  font-size: 12px;
  font-weight: bolder;
}

.ImageList_item__omJGo {
  border-bottom: 1px solid #eee;
  border-left: 6px solid transparent;
  color: #000;
  font-size: 12px;
  overflow: hidden;
  padding: 12px;
  text-overflow: ellipsis;
  transition: all linear 0.2s;
  white-space: nowrap;
}

.ImageList_item__omJGo:hover {
  background-color: #fff;
  color: #000;
}

.ImageList_active__1Lu72 {
  background-color: #fff;
  border-left-color: #2ebcc0;
  font-weight: bolder;
}

.ImageList_prev__2myUL,
.ImageList_next__2IYx7 {
  color: #fff;
  display: inline-block;
  font-size: 18px;
  text-align: center;
  text-shadow: 0 1px 2px #000;
  vertical-align: middle;
  width: 10%;
}

.ImageList_prev__2myUL[disabled],
.ImageList_next__2IYx7[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.ImageList_prev__2myUL:hover,
.ImageList_next__2IYx7:hover {
  color: #fff;
}

.ImageList_counter__1-NHy {
  background: transparent;
  border: 0;
  color: inherit;
  display: inline-block;
  flex: 1 1;
  font-family: inherit;
  font-size: medium;
  font-size: initial;
  text-align: center;
  text-shadow: 0 1px 2px #000;
  transition: all linear 0.2s;
  vertical-align: middle;
}

.ImageList_counter__1-NHy:hover {
  cursor: pointer;
  opacity: 0.8;
}

.ImageList_counter__1-NHy > svg {
  margin-left: 10px;
}

.SiblingNav_nav__1u3LJ {
  background: rgba(255, 255, 255, 0.95);
  bottom: 20px;
  display: flex;
  height: 60px;
  left: calc(calc(calc(100% - 360px) / 2) - 100px);
  position: absolute;
  width: 200px;
  z-index: 2;
}

.SiblingNav_nav__1u3LJ > span {
  align-self: center;
  flex: 1 1;
  text-align: center;
}

.SiblingNav_button__VXoYZ {
  background: transparent;
  border: 0;
  flex: 1 1;
  font-size: 24px;
  max-width: 60px;
  padding: 0;
  transition: all linear 0.2s;
}

.SiblingNav_button__VXoYZ:hover:not([disabled]) {
  background: #fff;
  cursor: pointer;
}

.SiblingNav_button__VXoYZ[disabled],
.SiblingNav_button__VXoYZ[disabled] ~ span {
  opacity: 0.5;
}

.Labeling_wrapper__3SIO8 {
  display: flex;
  height: 100%;
  outline: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.AnnotationListItem_row__2x4gV {
  border-bottom: 1px solid #eee;
  border-left: 6px solid transparent;
  padding: 12px;
  transition: all linear 0.2s;
}

.AnnotationListItem_row__2x4gV:hover {
  background: #fff;
  cursor: pointer;
}

.AnnotationListItem_rowActive__CxhiS {
  background: #fff;
  border-left-color: #2ebcc0;
}

.AnnotationListItem_header__2RWdY {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.AnnotationListItem_header__2RWdY h5 {
  font-size: 12px;
  margin: 0;
}

.AnnotationListItem_trash__2qvua {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.Box_box__44Im9 {
  background-color: rgba(255, 255, 255, .95);
	box-shadow: 0 0 12px rgba(38, 48, 76, 0.2);
	color: #424c5d;
	margin-bottom: 40px;
	position: relative;
  width: 25%;
  margin: 10px;
}

.Box_small__3OFoi {
  width: 320px;
  margin-bottom: 20px;
}

.Box_box__44Im9 > header {
  border-bottom: 1px solid #bac8c8;
  padding: 30px 30px 20px;
  height: 64px;
}

.Box_small__3OFoi > header {
  border-bottom: 1px solid #bac8c8;
  padding: 20px 20px 15px;
}

.Box_box__44Im9 > header > h4 {
  margin-bottom: 0;
}

.Box_box__44Im9 ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h4 {
  float:left;
  margin-right:10px;
}

.Box_count__o_DWT{
  background-color: #454965;
  color: white;
  border-radius: 100px;
  display: inline-block;
  padding: 6px;
  text-align: center;
  min-width: 27px;
  font-size: .6em;
}

.AnnotationsList_addButton__3ESdV {
  background: #2ebcc0;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 28px;
  line-height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 20px;
  text-align: center;
  transition: background linear 0.2s;
  top: 20px;
  width: 28px;
}

.AnnotationsList_addButton__3ESdV:hover {
  background: #3acdd1;
}

.YesNoSwitch_label__rRVaT {
  display: block;
  margin-bottom: 8px;
}

.YesNoSwitch_input__jHmXG {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.YesNoSwitch_slider__1zEfo {
  border: 1px solid #ccc;
  height: 36px;
  position: relative;
  width: 90px;
}

.YesNoSwitch_slider__1zEfo::after {
  background-color: #fff;
  content: '';
  cursor: pointer;
  display: block;
  height: 100%;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: left linear 0.4s;
  width: 50%;
}

.YesNoSwitch_sliderChecked__1fpgl {
  border-color: #16b2b8;
}

.YesNoSwitch_sliderChecked__1fpgl::after {
  left: 0;
}

.YesNoSwitch_no__24tE5,
.YesNoSwitch_yes__2Wtgl {
  color: #fff;
  display: inline-block;
  height: 100%;
  line-height: 36px;
  text-align: center;
  width: 50%;
}

.YesNoSwitch_no__24tE5 {
  background-color: #ccc;
}

.YesNoSwitch_yes__2Wtgl {
  background-color: #16b2b8;
}

.PolylineListItem_row__LdBjI {
  border-bottom: 1px solid #eee;
  border-left: 6px solid transparent;
  padding: 7px;
  transition: all linear 0.2s;
}

.PolylineListItem_row__LdBjI:hover {
  background: #fff;
  cursor: pointer;
}

.PolylineListItem_rowActive__2IiR5 {
  background: #fff;
  border-left-color: #2ebcc0;
}

.PolylineListItem_header__3sw1B {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-right:20px;
}

.PolylineListItem_header__3sw1B h5 {
  flex: 1 1;
  font-size: 12px;
  margin: 0;
}

.PolylineListItem_trash__kglkM,
.PolylineListItem_visible__2MvRj {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.PolylineListItem_trash__kglkM {
  margin-right: 10px;
}

.PolylineListItem_inputRow__1680- {
  display: flex;
}

.PolylineListItem_inputRow__1680- > * {
  flex: 1 1;
  max-width:36%;
  margin-left:2px;
  margin-right:2px;
}

.PolylineListItem_inputRow__1680- > input {
  height:2.5em;
  max-width:15%;
}
.PolylineListItem_inputRow__1680- > select {
  height:2.5em;
}
.LabelingSidebar_sidebar__1RlBy {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  max-width: 360px;
  overflow-y: auto;
  padding: 0 20px 20px;
}

.LabelingSidebar_sidebar__1RlBy > * {
  flex: 1 1;
  margin: 10px 0;
  max-height: calc(50% - 10px);
  min-height: calc((100% - 40px) / 3);
  z-index: 2;
}

.LabelingSidebar_sidebar__1RlBy > :first-child {
  margin-top: 0;
}

.LabelingSidebar_sidebar__1RlBy > :last-child {
  margin-bottom: 0;
}

.LabelingSidebar_sidebar__1RlBy > :only-child {
  margin: 0;
  max-height: 100%;
  min-height: auto;
}

/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  color: #212529;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

h1, h2, h3, h4, h5 {
  color: inherit;
  font-family: inherit;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

p {
  margin-bottom: 0.5rem;
  margin-top: 0;
}

a {
  background-color: transparent;
  color: #2ebcc0;
  text-decoration: none;
  transition: all linear 0.1s;
}

a:hover {
	color: #33ccd0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

fieldset > label {
  display: block;
}

label {
  font-size: 14px;
}

input,
select,
textarea {
  background-color: white;
  border: 1px solid #bac8c8;
  color: #424c5d;
  font-size: 0.8em;
  height: 44px;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #cccccc;
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #cccccc;
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: #cccccc;
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
}

input::placeholder,
textarea::placeholder {
  color: #cccccc;
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
}

select {
  cursor: pointer;
  transition: all linear 0.2s;
}

select:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

button:active,
button:focus,
input:active,
input:focus,
select:active,
select:focus,
textarea:active,
textarea:focus {
  outline: none;
}

section[role=main] {
  background-color: #404672;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#444444', endColorstr='#333333', GradientType=0);
  background: linear-gradient(#404672, #49395e), url(https://www.allvision.io/wp-content/uploads/2020/01/noise_tile_trans.png);
  background-blend-mode: overlay;
  background-size: 72px;
  height: inherit;
  padding-top: 78px;
  position: relative;
}

/* simplebar overrides */

.simplebar-scrollbar {
  width: 10px;
}

.simplebar-scrollbar:before {
  background: #c1cdce;
  border-radius: 0;
}

